// custom functions
const FE = {
	location: window.location,

    Lightbox: {
	    init: function () {
            var lightbox = new Tobii({zoom: false});
        }
    },

    MobileMenu: {
        init: function() {
            const burgerIcon = document.getElementById('burger-icon');
            const navMain = document.getElementById('nav-main');
            burgerIcon.addEventListener("click", function () {
                if ( navMain.classList.contains('active') ) {
                    this.setAttribute('aria-expanded', false);
                    navMain.classList.remove('active');
                } else {
                    this.setAttribute('aria-expanded', true);
                    navMain.classList.add('active');
                }
            });
        }

    },

    HeroSlider: {
        init: function () {
            var sliderContainer = document.querySelector('.ce-hero-slider-slides');
            var slides = document.querySelectorAll('.ce-hero-slider-slides figure');
            //console.log(sliderContainer, slides);
            this.setupSliderMarkup(sliderContainer, slides);
        },
        setupSliderMarkup: function (sliderContainer, slides, controls=false) {
            var sliderEl = document.createElement('div');
            var sliderTrack = '<div class="glide__track" data-glide-el="track"></div>';
            var sliderControls ='<div class="glide__arrows" data-glide-el="controls"><button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button><button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button></div>';

            var slidesCount = slides.length;
            for (var i=0; i < slidesCount; i++) {
                slides[i].classList.add('glide__slide');
            }

            sliderEl.classList.add('glide');
            sliderContainer.parentNode.insertBefore(sliderEl, sliderContainer);
            sliderContainer.classList.add('glide__slides');

            var sliderInnerContent = sliderTrack;
            if (controls === true) {
                sliderInnerContent += sliderControls;
            }
            sliderEl.innerHTML = sliderInnerContent;

            var sliderTrackEl = sliderEl.querySelector('.glide__track');
            sliderTrackEl.appendChild(sliderContainer);

            new Glide('.ce-hero_slider .glide', {
                type: 'carousel',
                autoplay: 5000,
                hoverpause: false
            }).mount();
        }
    },

};

// init custom functions
window.addEventListener("DOMContentLoaded", function () {

    FE.MobileMenu.init();
    if ( document.querySelectorAll('.lightbox').length ) {
        FE.Lightbox.init();
    }
    if ( document.querySelectorAll('.ce-hero-slider-slides figure').length > 1 ) {
        FE.HeroSlider.init();
    }
});